@tailwind base;
@tailwind components;
@tailwind utilities;

/* Force dark mode and theme colors */
:root {
  prefers-color-scheme: dark !important;
}

@layer base {
  body {
    @apply bg-[#0B1215] text-[#D6DADB] antialiased;
  }
}

@layer components {
  .btn-primary {
    @apply bg-primary px-6 py-3 rounded-lg font-medium hover:bg-primary-hover transition-colors;
  }
  
  .btn-secondary {
    @apply bg-white/10 px-6 py-3 rounded-lg font-medium hover:bg-white/20 transition-colors;
  }
}

/* Glass card effect */
.glass-card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

/* Force dark backgrounds for main areas */
main, 
.chat-window, 
.sidebar,
#root,
[class*="bg-gray-"] {
  background-color: #111827 !important;
  color: white !important;
}

/* Force input backgrounds to be dark */
input, 
textarea, 
select {
  background-color: #1f2937 !important;
  color: white !important;
}

/* Loaders */
.barLoader {
  width: 8px;
  height: 48px;
  display: inline-block;
  position: relative;
  border-radius: 4px;
  color: #FFF;
  box-sizing: border-box;
  animation: animloader 0.6s linear infinite;
}

.loaderX {
  position: relative;
  width: 26.5625px;
  height: 15.625px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#6d00d4 15.625px, transparent 0),
                    linear-gradient(#8023d8 15.625px, transparent 0),
                    linear-gradient(#a854f7 15.625px, transparent 0),
                    linear-gradient(#bf80fb 15.625px, transparent 0),
                    linear-gradient(#e9d2ff 15.625px, transparent 0),
                    linear-gradient(#ffffff 15.625px, transparent 0);
  background-position: 0px center, 4.6875px center, 9.375px center, 14.0625px center, 18.75px center, 23.4375px center;
  animation: rikSpikeRoll 0.65s linear infinite alternate;
}

@keyframes rikSpikeRoll {
  0% { background-size: 3.125px 0.9375px; }
  16% { background-size: 3.125px 15.625px, 3.125px 0.9375px, 3.125px 0.9375px, 3.125px 0.9375px, 3.125px 0.9375px, 3.125px 0.9375px; }
  33% { background-size: 3.125px 9.375px, 3.125px 15.625px, 3.125px 0.9375px, 3.125px 0.9375px, 3.125px 0.9375px, 3.125px 0.9375px; }
  50% { background-size: 3.125px 3.125px, 3.125px 9.375px, 3.125px 15.625px, 3.125px 0.9375px, 3.125px 0.9375px, 3.125px 0.9375px; }
  66% { background-size: 3.125px 0.9375px, 3.125px 3.125px, 3.125px 9.375px, 3.125px 15.625px, 3.125px 0.9375px, 3.125px 0.9375px; }
  83% { background-size: 3.125px 0.9375px, 3.125px 0.9375px, 3.125px 3.125px, 3.125px 9.375px, 3.125px 15.625px, 3.125px 0.9375px; }
  100% { background-size: 3.125px 0.9375px, 3.125px 0.9375px, 3.125px 0.9375px, 3.125px 3.125px, 3.125px 9.375px, 3.125px 15.625px; }
}
